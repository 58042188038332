import { Link,Route,Routes} from "react-router-dom";
import ChatGpt from './componenets/pages/ChatGpt';
import Home from "./componenets/pages/Home";
import Chat from "./componenets/pages/Chat";
import GenerateImage from "./componenets/pages/ChatImage";
export default function App(){
  const linkstyle={color:'#fff'}

  return(
    <>
      <nav className="navbar navbar-expand-lg" style={{background:"black",position: 'fixed',width: '100%',zIndex: '200',}}>
        <div className="container">
          <a className="navbar-brand" href="/" style={linkstyle}>
            Chat <i class="fa-solid fa-crosshairs"></i> <span style={{color:'red'}}>IBI27A.</span>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" style={linkstyle}><i class="fa-solid fa-bars"></i></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/" style={linkstyle}><i class="fa-solid fa-house"></i> Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/chat" style={linkstyle}><i class="fa-solid fa-comments"></i> Chat-Ai</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/chatgpt" style={linkstyle}><i class="fa-solid fa-message"></i> Chat-Gpt</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/generateimage" style={linkstyle}><i class="fa-solid fa-image"></i> Generate Image</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/chatgpt" element={<ChatGpt />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/generateimage" element={<GenerateImage/>}></Route>
          <Route path="*" element={<Home/>}></Route>
        </Routes>
      </div>
      <footer className="footer">
        <ul style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              listStyle: 'none',

        }}>
          <li className="m-2"><Link to='https://github.com/AYOUB20IBI' style={{textDecoration: 'none',color:'#fff'}}><i class="fa-brands fa-github"></i> Github</Link></li>
          <li className="m-2"><Link to='https://www.linkedin.com/' style={{textDecoration: 'none',color:'#fff'}}><i class="fa-brands fa-linkedin"></i> Linkedin</Link></li>
          <li className="m-2"><Link to='https://web.telegram.org/'  style={{textDecoration: 'none',color:'#fff'}}><i class="fa-brands fa-telegram"></i> Telegram</Link></li>
        </ul>
      </footer>
    </>
  )
}