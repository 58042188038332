import { useState } from "react";
import './chatgpt.css';
// import './chat.css';
import axios from "axios";

export default function ChatGpt() {
    const [textchat, setTextChat] = useState("")
    const [dataresult,setDataResult]=useState([]);
    const [error,setError]=useState("");

    const changeVal = () => {
        setTextChat(document.getElementById('chat').value)
    }

    const handelClick = (e) => {
        e.preventDefault();
        setError('Please Wait . . .');
        let body = {
            "model": "gpt-3.5-turbo",
            "messages": [{ "role": "user", "content": textchat }],
            "temperature": 1,
        }

        axios.post('https://api.openai.com/v1/chat/completions', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 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',
            },
        })
        .then((res) => {
            // console.log(res.data.choices[0].message.content);
            setDataResult(res.data.choices[0].message.content)
            setError("\n")
            setTextChat('');
        })
        .catch((error) => console.error('Error:', error));

    }

    return (
        <>
            <div className="container">
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card chat-app">
                            <div className="message-res">
                                <p>{dataresult}<br/>{error}</p>
                            </div>
                            <div className="chat-message clearfix">
                                <div className="input-group mb-0">
                                    
                                    <input type="text" className="form-control" placeholder="Message Chat-Gpt..." id="chat" value={textchat} onChange={changeVal} style={{color:'#fff', background: '#898989d6'}}/>
                                    <button  style={{ background: '#18191e'}} onClick={handelClick}><i className="fa-solid fa-plus"></i></button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
