import './chatimage.css';
import axios from "axios";
import { useState } from 'react';
import { saveAs } from 'file-saver';
export default function GenerateImage(){
    const [story, setStory] = useState("")
    const [dataresult,setDataResult]=useState([]);
    const [error,setError]=useState("");

    const changeVal = () => {
        setStory(document.getElementById('story').value)
    }

    const handelGenerate = async (e) => {
        e.preventDefault();
        
        
        if (story !== '') {
            setError('Please Wait . . .');
            let body = {
                "model": "dall-e-3",
                "prompt": story,
                "n": 1,
                "size": "1024x1024"
            }
    
            await axios.post('https://api.openai.com/v1/images/generations', body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 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',
                },
            })
            .then((res) => {
                // console.log(res.data.data[0].url);
                setDataResult(res.data.data[0].url)
                setError("\n")
                setStory('');
            })
            .catch((error) => console.error('Error:', error));
        }else{
            alert('Please Entre Prompt')
        }

    }

    const downloadImage = () => {
        if (!dataresult){
            alert('No Image Found')
        }else{
            saveAs(dataresult,'ayoub.png')
        }
    }
    return(
        <>
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-md-9" id='card'>
                    <div className="card p-4 mt-3" style={{background: '#000000b5'}}>
                        <h3 className="heading mt-5 text-center" style={{marginBottom: '20px',color:'#fff'}}>Hi! Generate an image ?</h3>
                        <div className="d-flex justify-content-center">
                            <div id='cardgenerate'>
                                            
                                <input type="text" id='story'  placeholder="Enter Prompt Here..." onChange={changeVal}  
                                style={{outline:'none' ,width: '100%',border:'1px solid',height: '7vh',marginBottom: '10px',background: '#101622',color: '#fff',textAlign: 'center',borderRadius: '5px'}}/>
                                
                                <button  style={{background: 'rgb(239 0 0)',borderRadius: '5px'}} onClick={handelGenerate}>Generate</button>
                                            
                            </div>
                        </div>
                        <div className="row mt-4 g-1 px-4 mb-5">
                            {dataresult && (
                                <img  src={`${dataresult}`} alt='' />
                            )}
                            {error && (
                                <p className='text-center' style={{fontSize: "2rem",color:'rgb(177 177 177)'}}>{error}</p>
                            )}
                        </div>
                        
                        <div className="row mt-4 g-1 px-4 mb-5">
                            {dataresult.length !== 0 && (
                                <button onClick={downloadImage}>Download</button>
                            )}
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}